
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field } from "vee-validate";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import Contents from "@/components/reusable/Contents.vue";
import Insurer from "@/components/reusable/Insurer.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "contents-house-insurance",
  components: { Form, Field, TotalPremiumWidget, Contents, Insurer },
  props: { publicId: String },
  data() {
    return {
      showForm: false,
      underwriterId: "",
      cartItemExists: false,
      phoneRate: 0.05,
      laptopRate: 0.02,
      cameraRate: 0.01,
      inverterRate: 0.0025,
      musicalInstrumentRate: 0.01,
      jewelryRate: 0.01,
      displayContent: false,
      totalPremium: 0.0 as number,
      underwriters: [],
      result: {
        contentPublicId: "",
        hasContentCartItem: true || (false as boolean),
      },
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const { isEmptyArray } = ResusableFunctions();

    const submitForm = (values, { resetForm }) => {
      // console.clear();
      // return console.table(JSON.stringify(values, null, 2));

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const form = document.forms[0];
            const formData = new FormData(form);

            formData.append("totalPremium", values["totalPremium"]);
            formData.append("underwriter", values["underwriter"]);
            formData.append("businessClass", "HOM");
            formData.append("businessType", "HOU");
            formData.append("package", "CON");
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(variables.CART_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                console.clear();
                console.log(response);
                resetForm();
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: "insurance-packages-policy-period",
                  params: {
                    publicId: response.data.data.cartItemPublicId,
                  },
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Contents", ["Householders Insurance "]);
    });

    return {
      submitButton1,
      submitForm,
      isEmptyArray,
    };
  },
  created() {
    this.hasExistingCartPackages();
    this.getHouseBuildingRates();
    this.getHouseUnderwriters();

    //Set page title
    document.title =
      "Contents Householders Insurance | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    hasExistingCartPackages() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CHECK_CART_PACKAGE_ROUTE}/CON/new`)
          .then(({ data }) => {
            //Assign data to form fields
            this.result = data.data;
            this.cartItemExists = data.data.hasContentCartItem;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getHouseBuildingRates() {
      //Fetch list house content insurance package rates
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.HOUSE_BUILDING_RATE_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.phoneRate = data.data.phone.value;
            this.laptopRate = data.data.laptopOrTablet.value;
            this.cameraRate = data.data.camera.value;
            this.inverterRate = data.data.generatorOrInverter.value;
            this.musicalInstrumentRate = data.data.musicalInstrument.value;
            this.jewelryRate = data.data.jewelry.value;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getHouseUnderwriters() {
      //Fetch list of underwriters
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.HOUSE_UNDERWRITERS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.underwriters = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    handleArrayFromChild(response) {
      if (!this.isEmptyArray(response)) {
        this.underwriterId = response;
        this.showForm = true;
      }
    },
  },
});
